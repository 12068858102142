<template>
  <div class="timeList">
    <ul class="dis_flex">
      <li
        v-for="item in datejson.relativeListData"
        @click="changekList(item)"
        :class="{ current: currentidx == item.value && currentidx !== 11, customli: item.value === 11 }"
        :key="item.value"
      >
        <div class="passTime" v-if="item.value == 9">
          {{ item.label }}
          <el-input size="mini" v-model.number="passVal" @change="passValChange"></el-input>
          <el-select popper-class="thmemBottomMenu zIndexMax" v-model="timeunit" size="mini" placeholder="请选择" @change="passUnitChange">
            <el-option v-for="m in datejson.timeunits" :key="m.value" :label="m.label" :value="m.value"></el-option>
          </el-select>
        </div>
        <div class="relativePass" v-else-if="item.value == 11">
          <label v-if="currentidx != 11">{{ item.label }}</label>
          <template v-if="currentidx == '11'">
            <!-- 第一种 -->
            <pastDayToLast
              ref="FromPastDayToLast"
              class="passcustom"
              :class="{ customcurrent: customType === 'FromPastDayToLast' }"
              :currenttime="currenttime"
              @click.stop.native="checkCustom('FromPastDayToLast')"
              @timechange="timechange"
            />
            <!-- 第二种 -->
            <pastToPast
              ref="FromPastToPast"
              class="passcustom"
              :class="{ customcurrent: customType === 'FromPastToPast' }"
              :currenttime="currenttime"
              @click.stop.native="checkCustom('FromPastToPast')"
              @timechange="timechange"
            />
            <!-- 第三种 -->
            <lastToThis
              ref="FromLastToThis"
              class="passcustom"
              :class="{ customcurrent: customType === 'FromLastToThis' }"
              :currenttime="currenttime"
              @click.stop.native="checkCustom('FromLastToThis')"
              @timechange="timechange"
            />
          </template>
        </div>
        <div v-else>{{ item.label }}</div>
      </li>
    </ul>
    <!-- <div class="foot-btns" v-show="currentidx == 9 || currentidx == 11">
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button type="primary" size="small" @click="submitTime">确定</el-button>
    </div> -->
  </div>
</template>
<script>
import pastDayToLast from './relative/pastDayToLast'
import pastToPast from './relative/pastToPast'
import lastToThis from './relative/lastToThis'

import datejson from './datejson'
import datejs from './datetimejs.js'

// 相对自定义  初始化每一项的数据 点进来 默认选中第一项 更新 currenttime
// 切换自定义 更新 currenttime 自定义修改触发emit 更新 currenttime
// 回显
export default {
  props: ['currenttime', 'maxInterval'],
  data() {
    return {
      datejson: datejson,
      datejs: datejs,
      currentidx: '',

      timeunit: 'Day',
      passVal: 1,
      passCustom: 'Year',

      customType: 'FromPastDayToLast',
    }
  },
  components: {
    pastDayToLast,
    pastToPast,
    lastToThis,
  },
  created() {
    if (this.currenttime.relative) {
      this.init()
    } else {
      // datejs.getCustomRelative(this)
    }
  },
  methods: {
    init() {
      this.currentidx = datejs.getCurrentidx(this, this.currenttime)
      // datejs.getCustomRelative(this)
      if (this.currentidx == '11') {
        const relative = this.currenttime.relative
        if (!relative.mode && relative.defines.length == 2) {
          relative.mode = 'FromLastToThis'
        }
        if (relative.mode) {
          this.customType = relative.mode
        }
      }

      // if(!relative||(relative.mode && relative.mode !== 'FromLastToThis')||relative.defines.length == 1){
      // }
    },
    changekList(item) {
      datejs.checkList(this, item)
    },
    // 过去自定义
    checkCustom(type) {
      this.customType = type
      this.$refs[type][0].exportcurrenttime()
      // this.currenttime = this.$refs[type][0].exportcurrenttime()
      // datejs.getPassCustomTime(this, item)
    },
    customtimechange() {},
    changeitem(i, item) {
      this.$emit('changeitem', i, item)
    },
    passValChange() {
      datejs.getPassData(this)
    },
    passUnitChange() {
      this.currentidx = 9
      datejs.getPassData(this)
    },

    passCustomChange(item) {
      this.passCustom = item
      datejs.getCustomRelative(this, 'change')
      datejs.getPassCustomTime(this)
    },
    passMonthChange(idx) {
      //切换月份 更新每月天数
      const monthval = this.customThird[idx].monthVal
      let mon = ''
      if (idx == 0) {
        mon =
          this.moment()
            .subtract(1, 'year')
            .format('YYYY') +
          '-' +
          monthval
      } else {
        mon = this.moment().format('YYYY') + '-' + monthval
      }
      const dayslist = datejs.getMonDays(this, mon)
      this.$set(this.customThird[idx], 'days', dayslist)
      datejs.getPassCustomTime(this)
    },
    passDayChange() {
      datejs.getPassCustomTime(this)
    },
    // 第一种自定义
    customFirstChange() {
      datejs.getFromToTime(this)
    },
    // 第二种自定义
    customSecondChange() {
      datejs.getPassFromToTime(this)
    },
    timechange(item) {
      this.$emit('timechange', this.currenttime)
    },

    cancel() {
      this.$emit('timecancel')
    },
    submitTime() {
      this.$emit('timesubmit')
    },
  },
}
</script>

<style lang="scss">
.timeList {
  ul {
    li {
      &.customli {
        height: auto !important;
        background: none !important;
      }
      .relativePass {
        > label {
          display: block;
          width: 100%;
          height: 100%;
          background: rgba(58, 160, 255, 0.05);
        }
        .passcustom {
          margin-bottom: 10px;
          background: rgba(58, 160, 255, 0.05);
          height: 34px !important;
          line-height: 34px !important;
          &.customcurrent {
            @include high_bg1($highcolor-cheng);
            span {
              color: #fff;
            }
          }
          .passcustom-item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 34px !important;
            line-height: 34px !important;
          }
          > span {
            color: #333;
          }
          > .el-select {
            width: 75px;
            margin: 0 3px;
          }
          > .el-input {
            width: 75px;
            margin: 0 3px;
          }
          > .el-date-editor {
            width: 130px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.zIndexMax {
  z-index: 99999999 !important;
}
</style>
