<template>
  <div class="mb-datatimebox">
    <span class="mb-head-filter" @click="timeClick" slot="reference"><i class="iconfont iconfunnel"></i></span>
    <el-dialog
      class="thmemBottomMenu mb-thmemBottomMenu mb-datatime"
      custom-class="mb-datatime-dialog"
      :append-to-body="true"
      :show-close="false"
      :visible.sync="datatimevisible"
    >
      <div class="mb-datatime-box">
        <div class="dateitem">
          <label>时间维度</label><span id="allslot" class="label-r" @click="checkSlot">{{ allslotname }}<i class="el-icon-arrow-right"></i></span>
        </div>
        <!-- 时间类型 -->
        <div class="dateitem">
          <label>时间类型</label><span id="dateType" class="label-r" @click="checkDateType">{{ dateTypeval }}<i class="el-icon-arrow-right"></i></span>
        </div>
        <!-- 固定时间 -->
        <absolutetime
          v-if="currenttime.mode == 'Absolute'"
          :currenttime="currenttime"
          :maxInterval="maxInterval"
          @timechange="timechange"
          @timesubmit="timesubmit"
        />
        <!-- 相对时间 -->
        <relativetime
          v-if="currenttime.mode == 'Relative'"
          :currenttime="currenttime"
          :maxInterval="maxInterval"
          @timechange="timechange"
          @timesubmit="timesubmit"
        />
        <!-- 节假日时间 -->
        <festivaltime
          v-if="['CustomFestival', 'StatutoryFestival'].includes(currenttime.mode)"
          :key="currenttime.mode"
          :currenttime="currenttime"
          @timechange="timechange"
        />
        <div v-if="" class="mb-dialog-footer">
          <el-button size="mini" @click="timecancel">取消</el-button>
          <el-button type="primary" size="mini" @click="submitTime">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import datejson from './datejson.js'
import datejs from './datetimejs.js'
import absolutetime from './absolutetime.vue'
import relativetime from './relativetime.vue'
import festivaltime from './festivaltime.vue'

export default {
  props: ['proptimeidx', 'propDate', 'datetype', 'maxInterval'],
  data() {
    return {
      datatimevisible: false,
      showdata: '',
      currenttime: '',
      allFestivalName: '',
      datejson: datejson,
      dataTypes: [],
      dateTypeval: '固定时间',
      curtime: '',

      allslotname: '按天',
      slotval: '',
    }
  },
  components: {
    absolutetime,
    relativetime,
    festivaltime,
  },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
    }),
  },
  created() {
    this.dataTypes = this.datetype == 2 ? datejson.dataTypes2 : datejson.dataTypes1
    this.init()
  },
  methods: {
    init() {
      const _this = this
      if (this.proptimeidx >= 0) {
        const copy = JSON.parse(JSON.stringify(this.propDate))
        this.currenttime = copy[this.proptimeidx]
        this.showdata = datejs.getshowdata(this, this.currenttime)
      } else {
        this.currenttime = {
          dates: [],
          isCustomDatePeriod: false,
          mode: 'Absolute',
          unit: 'Week',
          value: 1,
        }
        // this.timeRange.push(this.currenttime)
        let date =
          this.moment()
            .subtract(14, 'days')
            .format('YYYY-MM-DD') +
          ' ~ ' +
          this.moment().format('YYYY-MM-DD')
        this.showdata = date
      }
      datejs.getDescription(this)
    },
    changeitem(i, item) {
      this.$emit('changeitem', i, item)
    },
    timeClick() {
      this.datatimevisible = true
      this.changeModel(this.currenttime.mode)
    },
    changeModel(item) {
      this.currenttime.mode = item
    },

    checkSlot() {
      const _this = this
      _this.$weui.picker(
        [
          {
            label: '按小时',
            value: 'hour',
          },
          {
            label: '按天',
            value: 'day',
          },
          {
            label: '按周',
            value: 'week',
          },
          {
            label: '按月',
            value: 'month',
          },
          { value: 'quarter', label: '按季度' },
        ],
        {
          className: 'custom-classname',
          container: 'body',
          defaultValue: ['day'],
          onChange: function(result) {},
          onConfirm: function(result) {
            _this.allslotname = result[0].label
            _this.slotval = result[0].value
          },
          id: 'allslot',
        }
      )
    },
    checkDateType() {
      const _this = this
      const mode = _this.currenttime.mode
      _this.$weui.picker(_this.dataTypes, {
        className: 'custom-classname',
        container: 'body',
        defaultValue: [mode],
        onChange: function(result) {},
        onConfirm: function(result) {
          _this.dateTypeval = result[0].label
          _this.changeModel(result[0].value)
        },
        id: 'dateType',
      })
    },

    timechange(t) {
      this.showdata = datejs.getshowdata(this, this.currenttime)
      this.curtime = t
    },
    timecancel() {
      this.datatimevisible = false
    },
    timesubmit() {
      let showdata = datejs.getshowdata(this, this.currenttime)
      if (!showdata) {
        this.$confirm('时间区间无效。', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          type: 'warning',
        })
          .then(() => {
            this.datatimevisible = true
          })
          .catch(() => {})
        return
      }

      let dates = [this.currenttime.start, this.currenttime.end]
      if (!datejs.checkInterval(this, dates)) {
        return
      }
      this.showdata = showdata
      datejs.formattime(this, this.currenttime)
      this.datatimevisible = false
    },

    submitTime() {
      if (!this.curtime) {
        this.$message.error({
          message: '请选择时间!',
          customClass: 'message-error-class',
          center: true,
        })
        return
      }
      const item = {
        time: {
          index: -1,
          v2time: [this.curtime],
        },
        slot: this.slotval || '',
      }
      this.$emit('timechange', item)
      this.datatimevisible = false
    },
  },
}
</script>
<style lang="scss">
@import './datatime.scss';
.el-message.message-error-class {
  padding: 10px;
  z-index: 99999 !important;
}
</style>
