<template>
  <div class="timeList">
    <ul class="dis_flex">
      <li v-for="item in datejson.absoluteListData" @click="changekList(item)" :class="{ current: currentidx == item.value }" :key="item.value">
        <div class="passTime" v-if="item.value == 9">
          {{ item.label }}
          <el-input size="mini" v-model.number="passVal" @change="passValChange"></el-input>
          <el-select popper-class="thmemBottomMenu zIndexMax" v-model="timeunit" size="mini" placeholder="请选择" @change="passUnitChange">
            <el-option v-for="m in datejson.timeunits" :key="m.value" :label="m.label" :value="m.value"></el-option>
          </el-select>
        </div>
        <div class="date-editor dis_flex_center" v-else-if="item.value == 11">
          <label>自定义</label>
          <el-date-picker
            v-model="customtime[0]"
            type="date"
            size="small"
            placeholder="开始日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOption[0]"
            @change="customtimechange"
          ></el-date-picker>
          <span>-</span>
          <el-date-picker
            v-model="customtime[1]"
            type="date"
            size="small"
            placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOption[1]"
            @change="customtimechange"
          ></el-date-picker>
        </div>
        <div v-else>{{ item.label }}</div>
      </li>
    </ul>
    <!-- <div class="foot-btns" v-show="currentidx == 9 || currentidx == 11">
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button type="primary" size="small" @click="submitTime">确定</el-button>
    </div> -->
  </div>
</template>
<script>
import datejson from './datejson'
import datejs from './datetimejs.js'
export default {
  props: ['currenttime', 'maxInterval'],
  data() {
    return {
      datejson: datejson,
      datejs: datejs,
      currentidx: '',

      timeunit: 'Day',
      passVal: 1,
      passCustom: 'Year',

      customtime: [],
    }
  },
  components: {
    // datetime,
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const _this = this
      this.customtime = [this.currenttime.start, this.currenttime.end]

      this.pickerOption = [
        {
          disabledDate(time) {
            return time.getTime() >= new Date(_this.customtime[1]).getTime()
          },
        },
        {
          disabledDate(time) {
            return time.getTime() < new Date(_this.customtime[0]).getTime() - 24 * 60 * 60 * 1000
          },
        },
      ]
    },
    changekList(item) {
      datejs.checkList(this, item)
    },
    changeitem(i, item) {
      this.$emit('changeitem', i, item)
    },
    passValChange() {
      datejs.getPassData(this)
    },
    passUnitChange() {
      this.currentidx = 9
      datejs.getPassData(this)
    },

    customtimechange() {
      this.currenttime.start = this.customtime[0]
      this.currenttime.end = this.customtime[1]
      this.$emit('timechange', this.getemittime())
    },
    getemittime() {
      return {
        mode: 'Absolute',
        start: this.currenttime.start,
        end: this.currenttime.end,
      }
    },

    cancel() {
      this.$emit('timecancel')
    },
    submitTime() {
      this.$emit('timesubmit')
    },
  },
}
</script>

<style lang="scss">
.zIndexMax {
  z-index: 99999999 !important;
}
</style>
