<template>
  <div ref="mblist" class="mbList" v-if="gridData2 && gridData2.length > 0 && chartData.length === gridData2.length">
    <grid-layout
      :layout.sync="gridData2"
      :responsive="true"
      :cols="{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }"
      :breakpoints="{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }"
      :col-num="12"
      :row-height="20"
      :is-draggable="false"
      :is-resizable="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true"
    >
      <grid-item v-for="item in gridData2" :key="item.id" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :minW="2" :minH="2">
        <div
          class="mbitem"
          :key="item.id"
          v-loading="chartData[item.i].loading"
          :element-loading-spinner="'cardloading loading' + basicInfo.project.mainColor"
          element-loading-background="rgba(0, 0, 0, 0)"
          :class="{ cardmbitem: chartData[item.i].option && chartData[item.i].reqObj.mode == 'card' }"
        >
          <!-- :style="{ height: (chartData[item.i].option && (chartData[item.i].reqObj.mode == 'card' || chartData[item.i].option.noData) ? (clientWidth * 3) / 5 : clientWidth) + 'px' }" -->
          <div class="mb-head wordellipsis">{{ chartData[item.i].reqObj.title }}</div>
          <div class="mb-time"></div>
          <div class="timebox dis_flex" v-if="chartData[item.i].timeRange">
            <datatime
              class="lefttime"
              :proptimeidx="0"
              :propitemidx="chartData[item.i].index"
              :propDate="chartData[item.i].timeRange"
              :isMobile="true"
              @timechange="time => gettimeRange1(chartData[item.i].index, chartData[item.i], time)"
              :key="JSON.stringify(chartData[item.i].timeRange[0])"
            ></datatime>
            <el-select
              class="set-contrast"
              popper-class="thmemBottomMenu"
              v-if="chartData[item.i].reqObj.contrastType && !chartData[item.i].reqObj.top"
              v-model="chartData[item.i].reqObj.contrastType"
              size="mini"
              @change="searchchange(chartData[item.i].index, chartData[item.i].reqObj, chartData[item.i].reqObj.contrastType, 'contrast')"
            >
              <el-option v-for="con in contrastoptions" :key="con.value" :label="con.label" :value="con.value"></el-option>
            </el-select>
            <span class="contrastspan" v-if="chartData[item.i].searchCode == 'contrast'">对比</span>
            <datatime
              class="righttime"
              :proptimeidx="1"
              :propitemidx="chartData[item.i].index"
              :propDate="chartData[item.i].timeRange"
              :isMobile="true"
              @timechange="time => gettimeRange2(chartData[item.i].index, chartData[item.i], time)"
              :key="JSON.stringify(chartData[item.i].timeRange[1])"
              v-if="chartData[item.i].timeRange[1] && (chartData[item.i].reqObj.contrastType == 'contrast' || chartData[item.i].searchCode == 'contrast')"
            ></datatime>
            <div
              class="set-slot"
              v-if="chartData[item.i].otherset.slot && slotshowarr.includes(chartData[item.i].otherset.slot) && !chartData[item.i].reqObj.top"
            >
              <el-select
                v-model="chartData[item.i].otherset.slot"
                popper-class="thmemBottomMenu"
                size="mini"
                @change="searchchange(chartData[item.i].index, chartData[item.i].reqObj, chartData[item.i].otherset.slot, 'slot')"
              >
                <el-option v-for="slot in slotoptions" :key="slot.value" :label="slot.label" :value="slot.value"></el-option>
              </el-select>
            </div>
          </div>
          <div
            v-if="chartData[item.i].searchCode === 'combination'"
            class="charts-box"
            :style="{
              height: fullchartidx == chartData[item.i].i ? 'calc(100% - 70px)' : chartData[item.i].timeRange ? 'calc(100% - 65px)' : 'calc(100% - 40px)',
            }"
            :key="tablefullkey"
          >
            <template>
              <div class="carddashboard" style="height: 100%">
                <carddashboard
                  :ref="'carddashboard' + chartData[item.i].i"
                  :chartDataItem="chartData[item.i]"
                  :griddata="chartData[item.i].reqObj.cardGrid"
                  :cardlist="chartData[item.i].reqObj.cardlist"
                  :dontclickable="true"
                  :griditem="chartData[item.i]"
                ></carddashboard>
              </div>
            </template>
          </div>
          <div
            class="charts-box"
            v-if="chartData[item.i].option && chartData[item.i].searchCode !== 'combination'"
            :style="{
              height: chartData[item.i].timeRange
                ? chartData[item.i].reqObj.contrastType == 'contrast' || chartData[item.i].searchCode == 'contrast'
                  ? 'calc(100% - 75px)'
                  : 'calc(100% - 50px)'
                : 'calc(100% - 25px)',
            }"
          >
            <template v-if="chartData[item.i].option && chartData[item.i].option.noData">
              <div class="nodata dis_flex_center">
                <div>
                  <img src="~@/assets/images/nodata-bg.png" alt />
                  <span>暂无数据</span>
                </div>
              </div>
            </template>
            <template v-else-if="chartData[item.i].cardinfos">
              <vue-bi-echarts-mobile
                v-if="chartData[item.i].cardinfos"
                :ref="'chart' + chartData[item.i].index"
                :key="'bicardkey' + chartData[item.i].index"
                :cardinfos="chartData[item.i].cardinfos"
                :rowheight="chartData[item.i].timeRange ? item.h * 20 + (item.h - 1) * 15 - 101 : item.h * 20 + (item.h - 1) * 15 - 68"
                :dontclickable="true"
                :style="{
                  height: !['table', 'table_hotmap', 'table_path'].includes(chartData[item.i].reqObj.mode)
                    ? chartData[item.i].prefilter
                      ? 'calc(100% - 20px) !important'
                      : '100%'
                    : chartData[item.i].option && chartData[item.i].option.opts && chartData[item.i].option.opts.enablePivot == 'full'
                    ? 'calc(100% - 100px)'
                    : (chartData[item.i].timeRange ? item.h * 20 + (item.h - 1) * 15 - 101 : item.h * 20 + (item.h - 1) * 15 - 68) + 'px',
                }"
              ></vue-bi-echarts-mobile>
            </template>
          </div>
        </div>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueGridLayout from '@/script/npmjs/vue-grid-layout.common.js'
import { getBookmarks, getDashboard } from '@/api/apiV2_dashboard'
import BI_config from '@/utils/config'
import Scheduler from '@/script/global/Scheduler.js'
import dashbdjs from '@/views/dashboard/dashboardjs'
import { allColor } from '@/datajs/static.js'

import cardinitMark from '@/views/dashboard/carddashboard/cardinitMark.js'

// import datatime from './datatime/datatime'
import datatime from './datatimeV2/index.vue'
// import echartItem from '@/components/echartItem'
// import MyTable from '@/components/tables/MyTable'
// import funneltable from '../dashboard/funneltable'
// import BIMap from '@/components/Map/Map'
import carddashboard from '@/views/dashboard/carddashboard/carddashboard'

export default {
  data() {
    return {
      dashboard: '',
      bookmarks: [],
      ismobile: true,
      position: [],
      chartdatacache: [],
      chartData: [],
      copyChartData: [],

      gridData2: [],

      allColor: null,
      datatimekey: 1,
      contrastoptions: [
        { value: 'nocontrast', label: '无对比' },
        { value: 'contrast', label: '对比' },
        { value: 'last', label: '同比' },
        { value: 'circle', label: '环比' },
        { value: 'lastandcircle', label: '同环比' },
      ],

      alltime: '',
      allslot: 'day',
      slotoptions: [
        { value: 'hour', label: '按小时' },
        { value: 'day', label: '按天' },
        { value: 'week', label: '按周' },
        { value: 'month', label: '按月' },
        { value: 'quarter', label: '按季度' },
      ],
      slotshowarr: ['hour', 'day', 'week', 'month', 'quarter'],

      parentset: {}, //父组件操作

      clientWidth: 0,

      tablefullkey: 0,
      chartsetidx: -1,
      fullchartidx: -1,

      setvisible: false,
      isMobile: false,
    }
  },
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    datatime,
    // echartItem,
    // MyTable,
    // funneltable,
    // BIMap,
    carddashboard,
  },
  computed: {
    ...mapState({
      dashboardHash: state => state.dashboardHash,
      basicInfo: state => state.basicInfo,
      pageunit: state => state.pageunit,
      projectID: state => state.projectID,
      system: state => state.system,
    }),
  },
  watch: {
    pageunit: function() {
      this.$pageunit = this.pageunit
    },
  },
  created() {
    const ua = navigator.userAgent
    const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
      isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
      isAndroid = ua.match(/(Android)\s+([\d.]+)/)
    this.isMobile = isIphone || isAndroid

    this.clientWidth = document.body.clientWidth - 20
    this.$pageunit = this.pageunit
    this.allColor = allColor
    this.init()
  },
  methods: {
    async init() {
      this.$store.commit('setFullPath', this.$route.fullPath)
      let dashboardID = this.$route.hash.replace('#/', '')
      if (!dashboardID && dashboardID !== 0) return
      let res = await getDashboard(dashboardID)
      this.dashboard = res.data || {}
      getBookmarks(this.system, dashboardID).then(v => {
        if (!v.error) {
          v.data = this.$commonJs.caseToggle(v.data)
          let detail = { dashboard: this.dashboard }
          let mobilebookmarks = []
          if (!this.dashboard.mobileSettings) {
            mobilebookmarks = v.data
              .filter(x => x.category != 'combination')
              .map(x => {
                x.position = { ...x.positionV2, x: 12, y: 6 }
                return x
              })
          } else {
            mobilebookmarks = this.dashboard?.mobileSettings?.bookmarks ?? []
          }
          let grids = []
          mobilebookmarks.forEach((mbook, midx) => {
            const findbook = v.data.find(b => b.id === mbook.id)
            this.bookmarks.push(findbook)
            const newgrid = { id: mbook.id, x: mbook.position.col, y: mbook.position.row, w: mbook.position.x, h: mbook.position.y, i: midx }
            // const newgrid = { id: mbook.id, x: 0, y: midx * 16, w: 12, h: 6, i: midx }
            grids.push(newgrid)
          })
          this.gridData2 = grids
          console.log(grids)

          detail.dashboard.bookmarks = this.bookmarks
          // this.bookmarks = v.data
          const scheduler = new Scheduler(5, this.$route.fullPath)
          this.bookmarks.forEach((el, i) => {
            this.copyChartData.push({ idx: i })
            dashbdjs.getChartData(this, BI_config, detail, el, i, scheduler, 'ismobile')
          })
          scheduler.start()
        }
      })
    },
    resetChartOption(idx, optdata) {
      optdata.color = this.initChartColor(this.chartData[idx])
      this.$set(this.chartData[idx], 'option', optdata)
    },
    initChartColor(cur) {
      let mainColoridx = 0
      let mainColor = null
      let chartColoridx = 0
      if (cur.version == '2.0') {
        mainColoridx = cur.rdMainColor != null && cur.rdMainColor != '0' ? cur.rdMainColor : this.basicInfo.project.mainColor

        mainColor = cur.rdMainColor > 0 ? this.allColor['color' + (mainColoridx - 1)] : this.allColor['color' + mainColoridx]
        chartColoridx = cur.rdChartColor != null ? cur.rdChartColor : 0
      } else {
        mainColoridx = this.basicInfo.project.mainColor
        mainColor = this.allColor['color' + mainColoridx]
        chartColoridx = cur.rdChartColor != null ? cur.rdChartColor : 0
      }
      return mainColor[chartColoridx]
    },
    gettimeRange1(idx, el, time, scheduler) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = idx
      newitem.item = el
      if (el.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem, BI_config, '', '', scheduler)
      } else {
        dashbdjs.gettimeRange(this, newitem, '', '', scheduler)
      }
      // this.datatimekey++
    },

    gettimeRange2(idx, el, time) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = idx
      newitem.item = el
      if (el.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem, BI_config, '', 'contrast')
      } else {
        dashbdjs.gettimeRange(this, newitem, '', 'contrast')
      }
      // this.datatimekey++
    },
    searchchange(idx, req, item, type) {
      this.chartData[idx].option = null
      dashbdjs.searchchange(this, idx, req, item, type)
    },
    cardMainColor(item) {
      let col = []
      let colors = ''
      if (item.version == '2.0') {
        if (item.rdMainColor == 0) {
          col = this.allColor['color' + this.basicInfo.project.mainColor]
        } else {
          col = this.allColor['color' + (item.rdMainColor - 1)]
        }
      } else {
        col = this.allColor['color' + this.basicInfo.project.mainColor]
      }

      colors = col[item.rdChartColor]
      let numberFontSize = item.option.numberFontSize
      // let size = numberFontSize == 'auto' ? '' : numberFontSize
      let size = numberFontSize == 'auto' ? '' : '36px'
      let fontsize = size ? 'font-size:' + size : ''
      let sty = fontsize ? 'color:' + colors[0] + ';' + fontsize : 'color:' + colors[0]
      return sty
    },
    changeAlltime(item) {
      const time = item.time
      const slot = item.slot
      this.alltime = time
      if (this.chartData.length == 0) {
        return
      }
      const scheduler = new Scheduler(5, this.$route.fullPath)
      this.chartData.forEach((el, idx) => {
        if (el.reqObj.timeRange || (el.reqObj.parameter && el.reqObj.parameter.timeRanges)) {
          if (el.version == '2.0') {
            el.reqObj.parameter.timeRanges[0] = JSON.parse(JSON.stringify(this.alltime.v2time[0]))
          } else {
            el.reqObj.timeRange[0] = JSON.parse(JSON.stringify(this.alltime.v2time[0]))
          }
          this.datatimekey++
          time.index = idx
          time.item = el

          this.gettimeRange1(idx, el, this.alltime.v2time[0], scheduler)
        }
      })
      scheduler.start()
    },
  },
  mounted() {},
}
</script>

<style lang="scss">
@import './datatime/datatime.scss';
@import './dashboardv2.scss';
</style>
