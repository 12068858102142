<template>
  <grid-layout
    v-if="gridData.length > 0 && cardList.length > 0"
    ref="gridkey"
    :layout.sync="gridData"
    :responsive="true"
    :breakpoints="{ lg: 400, md: 200, sm: 200, xs: 200, xxs: 0 }"
    :cols="{ lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }"
    :col-num="24"
    :row-height="initrowheight()"
    :max-rows="24"
    :is-draggable="false"
    :is-resizable="false"
    :vertical-compact="false"
    :margin="[0, 0]"
    :prevent-collision="true"
    @layout-created="layoutCreatedEvent"
    @layout-updated="layoutUpdatedEvent"
    @layout-ready="layoutReadyEvent"
  >
    <grid-item
      v-for="(item, idx) in gridData"
      :key="idx"
      :x="item.x"
      :y="item.y"
      :w="item.w"
      :h="item.h"
      :i="item.i"
      :minW="2"
      :minH="1"
      @resize="resizeEvent"
      @resized="resizedEvent"
    >
      <template v-if="!['word', 'table', 'table_more'].includes(cardList[idx].cardtype) && cardList[idx].option && cardList[idx].option.noData">
        <div class="nodata cardlistnodata dis_flex_center">
          <div>
            <img src="~@/assets/images/nodata-bg.png" alt />
            <span>暂无数据</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="cardtitle" v-if="cardList[idx].settings.styleSet.showCardTitle">
          <h2 :style="{ textAlign: cardList[idx].settings.styleSet.titleAlign || 'left' }">{{ cardList[idx].settings.styleSet.cardTitle }}</h2>
        </div>
        <div v-if="cardList[idx].cardtype == 'word' && cardList[idx].option" class="chartitem">
          <div class="words">
            <el-input
              type="textarea"
              readonly="true"
              autosize="true"
              resize="none"
              :class="cardList[idx].settings.styleSet.textAlign"
              :style="{
                fontSize: cardList[idx].settings.styleSet.numberFontSize,
              }"
              v-model="cardList[idx].option"
            ></el-input>
          </div>
        </div>
        <div class="biechartsbox" v-else-if="cardList[idx].cardinfos && $refs['gridkey']">
          <vue-bi-echarts
            :ref="'chart' + idx"
            :key="'bicardkey' + cardList[idx].bookmark.id"
            :chartDataItem="chartDataItem"
            :cardinfos="cardList[idx].cardinfos"
            :cardposition="changeitem(item)"
            :rowheight="cardList[idx].settings.styleSet.showCardTitle ? item.h * initrowheight() - 47 : item.h * initrowheight() - 27"
            :dontclickable="dontclickable"
            :style="{
              height: cardList[idx].settings.styleSet.showCardTitle ? item.h * initrowheight() - 25 + 'px' : item.h * initrowheight() - 5 + 'px',
            }"
            @changesort="td => changesort(td, idx)"
          ></vue-bi-echarts>
        </div>
      </template>
    </grid-item>
  </grid-layout>
</template>

<script>
import { mapState } from 'vuex'
import VueGridLayout from '@/script/npmjs/vue-grid-layout.common.js'
import echartItem from '@/components/echartItem'
import { allColor } from '@/datajs/static.js'

export default {
  props: ['chartDataItem', 'griddata', 'cardlist', 'griditem', 'cardidx', 'dragtype', 'dontclickable'],
  components: { GridLayout: VueGridLayout.GridLayout, GridItem: VueGridLayout.GridItem, echartItem },
  data() {
    return {
      gridData: [],
      cardList: [],

      allColor: null,
      dashboardReady: false,
    }
  },

  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
    }),
  },
  created() {},
  methods: {
    init() {},
    initrowheight() {
      if (this.$refs['gridkey']) {
        let h = this.$refs['gridkey'].$refs.item.clientHeight
        return h / 24
      } else {
        return 32
      }
    },
    changeitem(item) {
      let copyitem = JSON.parse(JSON.stringify(item))
      copyitem.w = Math.floor((this.griditem.w / 12) * (item.w / 2))
      return copyitem
    },
    layoutCreatedEvent() {},
    layoutUpdatedEvent() {},
    layoutReadyEvent() {},
    resizeEvent(i) {},
    debouncefuc(i) {
      if (this.cardList.length > 0 && this.cardList[i]) {
        let curcard = this.cardList[i]
        if (curcard.cardtype === 'word') {
        } else if (this.cardList[i].option && this.$refs[`chart${i}`] && !['table', 'table_more', 'table_hotmap', 'table_path'].includes(curcard.cardtype)) {
          let mychart = this.$echarts.init(this.$refs && this.$refs[`chart${i}`][0] && this.$refs[`chart${i}`][0].$refs[`echartitem${i}`])
          mychart.setOption(curcard.option, true)
          mychart.resize()
          curcard.chartkey++
        } else {
          curcard.chartkey++
        }
      }
    },
    resizedEvent(i) {},

    changesort(item, idx) {
      this.cardList[idx].sorttable = item
    },

    cardStyle(idx) {
      let colors = this.cardList[idx].chartColorV2
      return { color: colors[0], fontSize: this.cardList[idx].option.numberFontSize }
    },
    cardchange(i, item) {
      this.$set(this.cardList[i], 'curcardlabel', item)
      const curcard = this.cardList[i].option.arr.find(x => x.label === item)
      this.$set(this.cardList[i], 'curcard', curcard)
    },
  },
  mounted() {
    const _this = this
    this.allColor = allColor
    this.$nextTick(() => {
      setTimeout(() => {
        _this.gridData = _this.griddata
        _this.cardList = _this.cardlist
        _this.dashboardReady = true

        _this.init()
      }, 10)
    })
  },
}
</script>

<style lang="scss" scoped>
.vue-grid-layout {
  width: 100% !important;
  height: 100% !important;
  .vue-grid-item {
    .setChart {
      display: none;
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 9;
      color: #adb5bd;
      i {
        cursor: pointer;
      }
    }
    .cardtitle {
      color: #333;
      line-height: 20px;
    }
    .chartitem {
      height: 100%;
      .words {
        padding: 5px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        ::v-deep .el-textarea {
          display: block;
          width: 100%;
          .el-textarea__inner {
            border: none;
            word-break: break-all;
            @include theme_color2($theme-light);
          }
          &.left {
            .el-textarea__inner {
              text-align: left;
            }
          }
          &.right {
            .el-textarea__inner {
              text-align: right;
            }
          }
          &.verticalcenter {
            .el-textarea__inner {
              text-align: center;
            }
          }
        }
      }
      .tableitem {
        border-top: 1px solid #ebeef5;
      }
    }
    .nodata {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      // background: #f5f7fa;
      img {
        display: block;
        width: 80px;
        margin: 0 auto;
      }
      span {
        margin: 0 5px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        color: #adb5bd;
      }
    }
    .biechartsbox {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
