<template>
  <div>
    <span>从</span>
    <el-date-picker
      v-model="customFirst[0].datetimeval"
      type="date"
      size="mini"
      placeholder="开始日期"
      value-format="yyyy-MM-dd"
      @change="customtimechange"
    ></el-date-picker>
    <span>至</span>
    <el-select popper-class="thmemBottomMenu zIndexMax" v-model="dateType" size="mini" placeholder="请选择" @change="customFirstChange">
      <el-option v-for="m in customFirst[1].passcustomopts" :key="m.value" :label="m.label" :value="m.value"></el-option>
    </el-select>
  </div>
</template>

<script>
import datejson from '../datejson'
export default {
  props: ['currenttime'],
  data() {
    return {
      dateType: 'Day', //第一种 时间粒度
      customFirst: [], // 第一种
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const relative = this.currenttime.relative
      // 初始化 是否回显
      if (!relative || (relative.mode && relative.mode !== 'FromPastDayToLast') || relative.defines.length == 1) {
        this.customFirst = [
          {
            datetimeval: this.moment()
              .subtract(14, 'days')
              .format('YYYY-MM-DD'),
          },
          {
            passcustomopts: datejson.customDateSize,
          },
        ]
      } else {
        // 若是回显
        this.dateType = relative.unit
        if (relative.defines.length == 2) {
          this.customFirst = [
            {
              datetimeval: relative.defines[0].value,
            },
            {
              passcustomopts: datejson.customDateSize,
            },
          ]
          this.getCustomRelative()
        }
      }
    },
    customtimechange() {
      this.exportcurrenttime()
    },
    // 第一种自定义
    customFirstChange(item) {
      this.exportcurrenttime()
    },
    getFromToTime(item) {
      // 昨日 / 上周 / 上月 / 去年  上周对应上周日，上月对应上个月最后一天，去年对应去年12月31日
      relative = {
        unit: this.customDate1,
      }
    },
    getCustomTime() {
      let relative = {
        mode: 'FromPastDayToLast',
        unit: this.dateType,
        defines: [
          {
            value: this.customFirst[0].datetimeval,
          },
          {
            unitValue: -1,
          },
        ],
      }
      this.currenttime.relative = relative
      this.$emit('timechange')
    },
    getCustomRelative(ischange) {
      this.currenttime.unit = this.dateType
      this.currenttime.start = this.customFirst[0].datetimeval
      if (this.dateType === 'Year') {
        const lastdate =
          this.moment()
            .subtract(1, 'year')
            .format('YYYY') + '-12-31'
        this.currenttime.end = lastdate
      }
      if (this.dateType === 'Month') {
        const lastdate = this.moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD')
        this.currenttime.end = lastdate
      }
      if (this.dateType === 'Week') {
        const lastdate = this.moment()
          .weekday(0)
          .format('YYYY-MM-DD')

        this.currenttime.end = lastdate
      }
      if (this.dateType === 'Day') {
        const lastdate = this.moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
        this.currenttime.end = lastdate
      }
    },

    exportcurrenttime() {
      this.getCustomRelative()
      this.getCustomTime()
    },
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
  .zIndexMax{
    z-index: 99999999 !important;
  }
</style>