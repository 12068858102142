import store from '@/store'
import { getFestival } from '@/api/common'
const datejs = {
  //获取节假日
  getDescription(_this) {
    let time = []
    if (_this.currenttime.mode == 'Relative') {
      const gettime = datejs.getshowdata(_this, _this.currenttime, 'nofrom')
      time = (gettime && gettime.split('~')) || []
    } else {
      time = [_this.currenttime.start, _this.currenttime.end]
    }
    // 开始结束时间比较 是否相同或在之前
    if (time.length === 2 && _this.moment(time[0]).isSameOrBefore(_this.moment(time[1]))) {
      let p = {
        start: time[0],
        end: time[1],
      }
      if (getFestival) {
        getFestival(p).then(v => {
          _this.allFestivalName = v.data.map(x => {
            x.label = x.type == 'Statutory' ? '法定节假日' : ' 自定义节假日'
            return x
          })
        })
      }
    }
  },
  getCurrentidx(_this, timeRange) {
    let curidx = ''
    let time = timeRange.relative
    if (time.unit == 'All') {
      curidx = 10
    } else {
      if (time.defines.length == 1 && time.defines[0].unitValue) {
        let unitValue = time.defines[0].unitValue
        unitValue = unitValue < 0 ? -unitValue : unitValue
        let unit = time.unit + '_' + unitValue
        _this.passCustom = time.unit
        if (unitValue <= 1) {
          let cur = _this.datejson.absoluteListData.find(x => {
            return x.unit == unit
          })
          curidx = cur.value
        } else {
          curidx = 9
          _this.passVal = unitValue
          _this.timeunit = time.unit
        }
      }
      if (time.defines.length == 2) {
        curidx = 11
        // _this.passCustom = time.unit
        // time.defines.forEach((x) => {
        //   if (time.unit == 'Year') {
        //     let valueSplit = x.value.split('-')
        //     let list = {
        //       monthVal: parseInt(valueSplit[0]),
        //       dayVal: parseInt(valueSplit[1]),
        //     }
        //     _this.customThird.push(list)
        //   }
        //   if (['Month', 'Week'].includes(time.unit)) {
        //     let list = {
        //       dayVal: x.value,
        //     }
        //     _this.customThird.push(list)
        //   }
        // })
      }
    }

    return curidx
  },
  //获取展示时间
  getshowdata(_this, currenttime, nofrom) {
    if (currenttime.mode == 'Relative' && currenttime.relative) {
      const timeform = datejs.gettimeForm(_this, currenttime)
      const time = datejs.getRelativeData(_this, currenttime)
      const timearr = time.split('~')
      // 开始结束时间比较 是否相同或在之前
      if (_this.moment(timearr[0]).isSameOrBefore(_this.moment(timearr[1]))) {
        return timeform && !nofrom ? timeform + '(' + time + ')' : time
      } else {
        return
      }
    } else {
      return currenttime.start + ' ~ ' + currenttime.end
    }
  },
  //获取相对时间
  getRelativeData(_this, time, isContrast) {
    let relative = time.relative
    let defines = (time.relative && time.relative.defines) || []
    let date = []
    if (time.relative && time.relative.unit == 'All') {
      date[0] = _this.moment(store.state.basicInfo.project.onlineDate).format('YYYY-MM-DD')
      date[1] = _this.moment().format('YYYY-MM-DD')
    } else {
      //相对时间 非过去自定义
      if (defines.length == 1) {
        let unitValue = -Number(defines[0].unitValue)
        if (time.relative.unit == 'Day') {
          if (unitValue != '0') {
            date[0] = _this
              .moment()
              .subtract(unitValue, 'days')
              .format('YYYY-MM-DD')
            date[1] = _this
              .moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')
          } else {
            date[0] = _this.moment().format('YYYY-MM-DD')
            date[1] = _this.moment().format('YYYY-MM-DD')
          }
        }
        if (time.relative.unit == 'Week') {
          if (unitValue != '0') {
            date[0] = _this
              .moment()
              .add(-unitValue, 'week')
              .startOf('week')
              .format('YYYY-MM-DD')
            date[1] = _this
              .moment()
              .weekday(-1)
              .format('YYYY-MM-DD')
          } else {
            date[0] = _this
              .moment()
              .weekday(0)
              .format('YYYY-MM-DD')
            date[1] = _this.moment().format('YYYY-MM-DD')
          }
        }
        if (time.relative.unit == 'Month') {
          if (unitValue != '0') {
            date[0] =
              _this
                .moment()
                .subtract(unitValue, 'months')
                .format('YYYY-MM') + '-01'
            date[1] = _this
              .moment(_this.moment().format('YYYY-MM') + '-01')
              .subtract(1, 'days')
              .format('YYYY-MM-DD')
          } else {
            date[0] = _this.moment().format('YYYY-MM') + '-01'
            date[1] = _this.moment().format('YYYY-MM-DD')
          }
        }
        if (time.relative.unit == 'Year') {
          if (unitValue != '0') {
            date[0] =
              _this
                .moment()
                .subtract(unitValue, 'years')
                .format('YYYY') + '-01-01'
            date[1] = _this
              .moment(_this.moment().format('YYYY') + '-01-01')
              .subtract(1, 'days')
              .format('YYYY-MM-DD')
          } else {
            date[0] = _this.moment().format('YYYY') + '-01-01'
            date[1] = _this.moment().format('YYYY-MM-DD')
          }
        }
      }
      //相对时间 过去自定义
      if (relative.mode && relative.mode === 'FromPastDayToLast') {
        const unitValue = -Number(defines[1].unitValue)
        date[0] = defines[0].value
        if (relative.unit == 'Day') {
          date[1] = _this
            .moment()
            .subtract(unitValue, 'days')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Week') {
          date[1] = _this
            .moment()
            .weekday(-1)
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Month') {
          date[1] = _this
            .moment(_this.moment().format('YYYY-MM') + '-01')
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Year') {
          date[1] = _this
            .moment(_this.moment().format('YYYY') + '-01-01')
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
      }
      if (relative.mode && relative.mode === 'FromPastToPast') {
        const unitValue0 = -Number(defines[0].unitValue)
        const unitValue1 = -Number(defines[1].unitValue)
        if (relative.unit == 'Day') {
          date[0] = _this
            .moment()
            .subtract(unitValue0, 'days')
            .format('YYYY-MM-DD')
          date[1] = _this
            .moment()
            .subtract(unitValue1, 'days')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Week') {
          date[0] = _this
            .moment()
            .add(-unitValue0, 'week')
            .startOf('week')
            .format('YYYY-MM-DD')
          date[1] = _this
            .moment()
            .add(-unitValue1, 'week')
            .endOf('week')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Month') {
          date[0] =
            _this
              .moment()
              .subtract(unitValue0, 'months')
              .format('YYYY-MM') + '-01'
          date[1] = _this
            .moment(
              _this
                .moment()
                .subtract(unitValue1 - 1, 'months')
                .format('YYYY-MM') + '-01'
            )
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Year') {
          date[0] =
            _this
              .moment()
              .subtract(unitValue0, 'years')
              .format('YYYY') + '-01-01'
          date[1] = _this
            .moment(
              _this
                .moment()
                .subtract(unitValue1 - 1, 'years')
                .format('YYYY') + '-01-01'
            )
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
      }
      if ((!relative.mode && defines.length == 2) || (relative.mode && relative.mode === 'FromLastToThis')) {
        const defines0_value = defines[0].value
        const defines1_value = defines[1].value
        if (time.relative.unit == 'Week') {
          date[0] = _this
            .moment()
            .weekday(-8 + Number(defines0_value))
            .format('YYYY-MM-DD')
          date[1] = _this
            .moment()
            .weekday(defines1_value - 1)
            .format('YYYY-MM-DD')
        }
        if (time.relative.unit == 'Month') {
          const lastMonthDays = _this
            .moment()
            .subtract(1, 'months')
            .daysInMonth()
          const curMonthDays = _this.moment().daysInMonth()

          let day0 =
            Number(defines0_value) > 9 ? defines0_value : Number(defines0_value) > 0 ? '0' + defines0_value : lastMonthDays + Number(defines0_value) + 1
          let day1 = Number(defines1_value) > 9 ? defines1_value : Number(defines1_value) > 0 ? '0' + defines1_value : curMonthDays + Number(defines1_value) + 1
          date[0] =
            _this
              .moment()
              .subtract(1, 'months')
              .format('YYYY-MM') +
            '-' +
            day0
          date[1] = _this.moment().format('YYYY-MM') + '-' + day1
        }
        if (time.relative.unit == 'Year') {
          date[0] =
            _this
              .moment()
              .subtract(1, 'years')
              .format('YYYY') +
            '-' +
            defines0_value
          date[1] = _this.moment().format('YYYY') + '-' + defines1_value
        }
      }
    }
    if (isContrast) {
      return {
        start: date[0],
        end: date[1],
        mode: 'Absolute',
      }
    } else {
      return date[0] + '~' + date[1]
    }
  },
  gettimeForm(_this, date) {
    let timeform = ''
    if (date.mode == 'Relative' || date.model == 'Relative') {
      let unit = date.relative && date.relative.unit
      let defines = (date.relative && date.relative.defines) || []
      if (unit == 'All') {
        timeform = '上线至今'
      } else {
        if (defines.length == 2) {
          timeform = '过去自定义'
        } else {
          if (unit == 'Day') {
            timeform = defines[0].unitValue == '0' ? '今日' : defines[0].unitValue == '-1' ? '昨日' : '过去' + Math.abs(defines[0].unitValue) + '天'
          }
          if (unit == 'Week') {
            timeform = defines[0].unitValue == '0' ? '本周' : defines[0].unitValue == '-1' ? '上周' : '过去' + Math.abs(defines[0].unitValue) + '周'
          }
          if (unit == 'Month') {
            timeform = defines[0].unitValue == '0' ? '本月' : defines[0].unitValue == '-1' ? '上月' : '过去' + Math.abs(defines[0].unitValue) + '月'
          }
          if (unit == 'Year') {
            timeform = defines[0].unitValue == '0' ? '本年' : defines[0].unitValue == '-1' ? '去年' : '过去' + Math.abs(defines[0].unitValue) + '年'
          }
        }
      }
    }
    return timeform
  },

  checkList(_this, item) {
    _this.currentidx = item.value
    let date = []
    // 区分点击时间段
    if (item.unit == 'selected') {
      datejs.getPassData(_this)
      return false
    } else if (item.unit == 'pastcustom') {
      if (_this.currenttime.mode == 'Relative') {
        setTimeout(() => {
          _this.$refs['FromPastDayToLast'][0].exportcurrenttime()
        }, 10)
        // datejs.getPassCustomTime(_this, item)
      }
      return false
    } else if (item.unit == 'All') {
      date[0] = _this.moment(store.state.basicInfo.project.onlineDate).format('YYYY-MM-DD')
      date[1] = _this.moment().format('YYYY-MM-DD')
      if (!datejs.checkInterval(_this, date)) {
        return
      }
      _this.currenttime.unit = 'Year'
    } else {
      if (item.unit == 'Day_1') {
        date[0] = _this
          .moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
        date[1] = _this
          .moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      } else if (item.unit == 'Day_0') {
        date[0] = _this.moment().format('YYYY-MM-DD')
        date[1] = _this.moment().format('YYYY-MM-DD')
      } else if (item.unit == 'Week_1') {
        date[0] = _this
          .moment()
          .weekday(-7)
          .format('YYYY-MM-DD')
        date[1] = _this
          .moment()
          .weekday(-1)
          .format('YYYY-MM-DD')
      } else if (item.unit == 'Week_0') {
        date[0] = _this
          .moment()
          .weekday(0)
          .format('YYYY-MM-DD')
        date[1] = _this.moment().format('YYYY-MM-DD')
      } else if (item.unit == 'Month_1') {
        date[0] =
          _this
            .moment()
            .subtract(1, 'months')
            .format('YYYY-MM') + '-01'
        date[1] = _this
          .moment(_this.moment().format('YYYY-MM') + '-01')
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      } else if (item.unit == 'Month_0') {
        date[0] = _this.moment().format('YYYY-MM') + '-01'
        date[1] = _this.moment().format('YYYY-MM-DD')
      } else if (item.unit == 'Year_1') {
        date[0] =
          _this
            .moment()
            .subtract(1, 'years')
            .format('YYYY') + '-01-01'
        date[1] = _this
          .moment(_this.moment().format('YYYY') + '-01-01')
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      } else if (item.unit == 'Year_0') {
        date[0] = _this.moment().format('YYYY') + '-01-01'
        date[1] = _this.moment().format('YYYY-MM-DD')
      }
    }
    let units = item.unit.split('_')
    let curunit = units[0]

    _this.customtime = date
    let timeform = ''
    if (_this.currenttime.mode == 'Relative') {
      timeform = item.label
    }
    let time = date[0] + ' ~ ' + date[1]
    _this.showdata = timeform ? timeform + '(' + time + ')' : time
    _this.currenttime.start = date[0]
    _this.currenttime.end = date[1]
    _this.currenttime['relative'] = {
      unit: curunit,
      defines: [
        {
          unitValue: units[1] > 0 ? '-' + units[1] : units[1],
        },
      ],
    }
    datejs.formattime(_this, _this.currenttime)
  },
  //计算过去时间
  getPassData(_this) {
    let start = ''
    let end = ''
    if (_this.timeunit == 'Day') {
      start = _this
        .moment()
        .subtract(_this.passVal, 'days')
        .format('YYYY-MM-DD')
      end = _this
        .moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
    } else if (_this.timeunit == 'Week') {
      start = _this
        .moment()
        .add(-_this.passVal, 'week')
        .startOf('week')
        .format('YYYY-MM-DD')
      end = _this
        .moment()
        .weekday(-1)
        .format('YYYY-MM-DD')
    } else if (_this.timeunit == 'Month') {
      start = _this
        .moment()
        .subtract(_this.passVal, 'months')
        .date(1)
        .format('YYYY-MM-DD')
      end = _this
        .moment(_this.moment().format('YYYY-MM') + '-01')
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
    } else if (_this.timeunit == 'Year') {
      start =
        _this
          .moment()
          .subtract(_this.passVal, 'years')
          .format('YYYY') + '-01-01'
      end = _this
        .moment(_this.moment().format('YYYY') + '-01-01')
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
    }

    _this.currenttime.start = start
    _this.currenttime.end = end

    _this.currenttime['relative'] = {
      unit: _this.timeunit,
      defines: [
        {
          unitValue: _this.passVal > 0 ? '-' + _this.passVal : _this.passVal,
        },
      ],
    }
    datejs.formattime(_this, _this.currenttime)
  },

  getPassCustomTime(_this, item) {
    let units = item ? item.unit.split('_') : ''
    let relative = {
      unit: units[0],
      defines: [
        {
          unitValue: '-' + units[1],
        },
      ],
    }
    let lastDayVal = String(_this.customThird[0].dayVal)

    let curDayVal = String(_this.customThird[1].dayVal)
    if (_this.currentidx == 11) {
      relative = {
        unit: _this.passCustom == 'Day' ? 'Year' : _this.passCustom,
      }
      if (_this.passCustom == 'Year') {
        let curMon = String(_this.customThird[0].monthVal)
        let lastMon = String(_this.customThird[1].monthVal)

        lastDayVal = Number(lastDayVal) < 10 ? '0' + lastDayVal : lastDayVal
        curDayVal = Number(curDayVal) < 10 ? '0' + curDayVal : curDayVal
        curMon = curMon < 10 ? '0' + curMon : curMon
        lastMon = lastMon < 10 ? '0' + lastMon : lastMon
        relative.defines = [
          {
            unitValue: -1,
            value: curMon + '-' + lastDayVal,
          },
          {
            unitValue: 0,
            value: lastMon + '-' + curDayVal,
          },
        ]
      }
      if (_this.passCustom == 'Month') {
        relative.defines = [
          {
            unitValue: -1,
            value: lastDayVal,
          },
          {
            unitValue: 0,
            value: curDayVal,
          },
        ]
      }
      if (_this.passCustom == 'Week') {
        relative.defines = [
          {
            unitValue: -1,
            value: lastDayVal,
          },
          {
            unitValue: 0,
            value: curDayVal,
          },
        ]
      }
      if (_this.passCustom == 'Day') {
        _this.passCustom = 'Year'
        _this.$set(_this.currenttime, 'unit', 'Year')
        relative.defines = [
          {
            unitValue: -1,
            value: '01-01',
          },
          {
            unitValue: 0,
            value: '01-01',
          },
        ]
      }
    }
    _this.currenttime.relative = relative
  },

  getPassFromToTime(_this, item) {
    _this.currenttime.relative = relative
  },

  getCustomRelative(_this, ischange) {
    _this.currenttime.unit = _this.passCustom
    _this.currenttime.value = 0
    const gettime = datejs.getshowdata(_this, _this.currenttime, 'nofrom')
    let time = (gettime && gettime.split('~')) || []
    if (time.length === 0) {
      _this
        .$confirm('时间区间无效。', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          type: 'warning',
        })
        .then(() => {})
        .catch(() => {})
      return
    }
    if (_this.passCustom == 'Year') {
      let months = Array.apply(null, Array(12)).map(function(item, i) {
        return {
          value: i + 1 + '',
          label: i + 1 + '月',
        }
      })
      let lastCurMon =
        _this
          .moment()
          .subtract(1, 'year')
          .format('YYYY') + '-01'
      let curMon = _this.moment().format('YYYY') + '-01'
      let lastCurDays = datejs.getMonDays(_this, lastCurMon)
      let curDays = datejs.getMonDays(_this, curMon)
      if (_this.customThird.length == 2) {
        _this.customThird = [
          {
            passcustomopts: _this.datejson.passcustomopts1,
            monthVal: ischange ? '1' : String(_this.customThird[0].monthVal),
            months: months,
            dayVal: ischange ? '1' : String(_this.customThird[0].dayVal),
            days: lastCurDays,
          },
          {
            passcustomopts: _this.datejson.passcustomopts2,
            monthVal: ischange ? '1' : String(_this.customThird[1].monthVal),
            months: months,
            dayVal: ischange ? '1' : String(_this.customThird[1].dayVal),
            days: curDays,
          },
        ]
      } else {
        _this.customThird = [
          {
            passcustomopts: _this.datejson.passcustomopts1,
            monthVal: '1',
            months: months,
            dayVal: '1',
            days: lastCurDays,
          },
          {
            passcustomopts: _this.datejson.passcustomopts2,
            monthVal: '1',
            months: months,
            dayVal: '1',
            days: curDays,
          },
        ]
      }
      _this.currenttime.start = ischange ? lastCurMon + '-01' : time[0]
      _this.currenttime.end = ischange ? curMon + '-01' : time[1]
    } else if (_this.passCustom == 'Month') {
      let curMon = _this.moment().format('YYYY-MM')
      let lastCurMon = _this
        .moment()
        .subtract(1, 'months')
        .format('YYYY-MM')
      let curDays = datejs.getMonDays(_this, curMon)
      let lastCurDays = datejs.getMonDays(_this, lastCurMon)
      if (_this.customThird.length == 2) {
        _this.customThird = [
          {
            passcustomopts: _this.datejson.passcustomopts1,
            dayVal: ischange ? '1' : String(_this.customThird[0].dayVal),
            days: lastCurDays,
          },
          {
            passcustomopts: _this.datejson.passcustomopts2,
            dayVal: ischange ? '1' : String(_this.customThird[1].dayVal),
            days: curDays,
          },
        ]
      } else {
        _this.customThird = [
          {
            passcustomopts: _this.datejson.passcustomopts1,
            dayVal: '1',
            days: lastCurDays,
          },
          {
            passcustomopts: _this.datejson.passcustomopts2,
            dayVal: '1',
            days: curDays,
          },
        ]
      }
      _this.currenttime.start = lastCurMon + '-01'
      _this.currenttime.end = curMon + '-01'
    } else if (_this.passCustom == 'Week') {
      let nubs = ['一', '二', '三', '四', '五', '六']
      let weeks = Array.apply(null, Array(6)).map(function(item, i) {
        return {
          value: i + 1 + '',
          label: '周' + nubs[i],
        }
      })
      const sunday = { value: 0, label: '周日' }
      weeks.push(sunday)
      if (_this.customThird.length == 2) {
        _this.customThird = [
          {
            passcustomopts: _this.datejson.passcustomopts1,
            dayVal: ischange ? '1' : String(_this.customThird[0].dayVal),
            days: weeks,
          },
          {
            passcustomopts: _this.datejson.passcustomopts2,
            dayVal: ischange ? '1' : String(_this.customThird[1].dayVal),
            days: weeks,
          },
        ]
      } else {
        _this.customThird = [
          {
            passcustomopts: _this.datejson.passcustomopts1,
            dayVal: '1',
            days: weeks,
          },
          {
            passcustomopts: _this.datejson.passcustomopts2,
            dayVal: '1',
            days: weeks,
          },
        ]
      }

      let lastCurDay = _this
        .moment()
        .weekday(-6)
        .format('YYYY-MM-DD')
      let curDay = _this
        .moment()
        .weekday(1)
        .format('YYYY-MM-DD')
      _this.currenttime.start = ischange ? lastCurDay : time[0]
      _this.currenttime.end = ischange ? curDay : time[1]
    } else if (_this.passCustom == 'Day') {
      let months = Array.apply(null, Array(12)).map(function(item, i) {
        return {
          value: i + 1 + '',
          label: i + 1 + '月',
        }
      })
      let lastCurMon =
        _this
          .moment()
          .subtract(1, 'year')
          .format('YYYY') + '-01'
      let curMon = _this.moment().format('YYYY') + '-01'
      let lastCurDays = datejs.getMonDays(_this, lastCurMon)
      let curDays = datejs.getMonDays(_this, curMon)
      _this.customThird = [
        {
          passcustomopts: _this.datejson.passcustomopts1,
          monthVal: '1',
          months: months,
          dayVal: '1',
          days: lastCurDays,
        },
        {
          passcustomopts: _this.datejson.passcustomopts2,
          monthVal: '1',
          months: months,
          dayVal: '1',
          days: curDays,
        },
      ]

      _this.currenttime.start = time[0]
      _this.currenttime.end = time[1]
    }
  },

  getMonDays(_this, mon) {
    let dayLen = _this.moment(mon, 'YYYY-MM').daysInMonth()
    let days = Array.apply(null, Array(dayLen)).map(function(item, i) {
      return {
        value: i + 1 + '',
        label: i + 1 + '日',
      }
    })
    if (_this.passCustom == 'Month') {
      let backDays = [
        {
          value: '-1',
          label: '倒数第1天',
        },
        {
          value: '-2',
          label: '倒数第2天',
        },
        {
          value: '-3',
          label: '倒数第3天',
        },
      ]
      days = days.concat(backDays)
    }
    return days
  },
  formattime(_this, time) {
    let t = {}
    if (time.mode === 'Absolute') {
      t['start'] = time['start']
      t['end'] = time['end']
      t['mode'] = time['mode']
    } else if (time.mode === 'Relative') {
      t['mode'] = time['mode']
      t['relative'] = time['relative']
    } else {
      t['start'] = time['start']
      t['end'] = time['end']
      t['mode'] = time.mode
    }
    _this.$emit('timechange', t)
  },
  checkInterval(_this, dates) {
    if (_this.maxInterval == '12m') {
      let drr = Math.abs(_this.moment(dates[1]) - _this.moment(dates[0]))
      let day = parseInt(drr / (24 * 60 * 60 * 1000))
      if (day >= 366) {
        _this.$message({
          message: '查询时间跨度不能超过12个月！',
          type: 'warning',
        })
        return false
      }
    }
    return true
  },
}
export default datejs
