export default {
  dataTypes1: [
    {
      value: 'Absolute',
      label: '固定时间',
    },
    {
      value: 'Relative',
      label: '相对时间',
    },
    {
      value: 'StatutoryFestival',
      label: '法定节假日',
    },
    {
      value: 'CustomFestival',
      label: '自定义节假日',
    },
  ],
  dataTypes2: [
    {
      value: 'Absolute',
      label: '固定时间',
    },
    {
      value: 'Relative',
      label: '相对时间',
    },
  ],
  absoluteListData: [
    {
      value: 1,
      label: '昨日',
      unit: 'Day_1',
    },
    {
      value: 2,
      label: '今日',
      unit: 'Day_0',
    },
    {
      value: 3,
      label: '上周',
      unit: 'Week_1',
    },
    {
      value: 4,
      label: '本周',
      unit: 'Week_0',
    },
    {
      value: 5,
      label: '上月',
      unit: 'Month_1',
    },
    {
      value: 6,
      label: '本月',
      unit: 'Month_0',
    },
    {
      value: 7,
      label: '去年',
      unit: 'Year_1',
    },
    {
      value: 8,
      label: '本年',
      unit: 'Year_0',
    },
    {
      value: 9,
      label: '过去',
      unit: 'selected',
    },
    {
      value: 10,
      label: '上线至今',
      unit: 'All',
    },
    {
      value: 11,
      label: '自定义时间',
      unit: 'pastcustom',
    },
  ],
  relativeListData: [
    {
      value: 1,
      label: '昨日',
      unit: 'Day_1',
    },
    {
      value: 2,
      label: '今日',
      unit: 'Day_0',
    },
    {
      value: 3,
      label: '上周',
      unit: 'Week_1',
    },
    {
      value: 4,
      label: '本周',
      unit: 'Week_0',
    },
    {
      value: 5,
      label: '上月',
      unit: 'Month_1',
    },
    {
      value: 6,
      label: '本月',
      unit: 'Month_0',
    },
    {
      value: 7,
      label: '去年',
      unit: 'Year_1',
    },
    {
      value: 8,
      label: '本年',
      unit: 'Year_0',
    },
    {
      value: 9,
      label: '过去',
      unit: 'selected',
    },
    {
      value: 10,
      label: '上线至今',
      unit: 'All',
    },
    {
      value: 11,
      label: '过去自定义',
      unit: 'pastcustom',
    },
  ],
  timeunits: [
    {
      value: 'Day',
      label: '天',
    },
    {
      value: 'Week',
      label: '周',
    },
    {
      value: 'Month',
      label: '月',
    },
    {
      value: 'Year',
      label: '年',
    },
  ],
  passcustomopts1: [
    {
      value: 'Year',
      label: '上年',
    },
    {
      value: 'Month',
      label: '上月',
    },
    {
      value: 'Week',
      label: '上周',
    },
  ],
  passcustomopts2: [
    {
      value: 'Year',
      label: '本年',
    },
    {
      value: 'Month',
      label: '本月',
    },
    {
      value: 'Week',
      label: '本周',
    },
  ],

  // 自定义 1
  customDateSize: [
    {
      value: 'Day',
      label: '昨日',
    },
    {
      value: 'Week',
      label: '上周',
    },
    {
      value: 'Month',
      label: '上月',
    },
    {
      value: 'Year',
      label: '去年',
    },
  ],
  // 粒度字典 2
  dateDictionary: {
    Day: '天',
    Week: '周',
    Month: '月',
    Year: '年',
  },
}
