<template>
  <div class="mobile" id="mblayoutid">
    <mobile-head v-if="leftInfo" :propleftinfo="leftInfo" @changealltime="changeAlltime" class="head"></mobile-head>
    <div class="dashboardList">
      <mobile-dashboard ref="mbdashboard" v-if="$route.hash" :key="dashboardKey"></mobile-dashboard>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileHead from './head'
import mobileDashboard from './mobileDashboardv2'
export default {
  name: 'mobile',
  components: {
    mobileHead,
    mobileDashboard,
  },
  data() {
    return {
      leftInfo: '',
      dashboardKey: 0,
      showDashboard: false,
    }
  },
  computed: {
    ...mapState({
      menus: state => state.menus,
      dashboardHash: state => state.dashboardHash,
      basicInfo: state => state.basicInfo,
      projectID: state => state.projectID,
    }),
  },
  watch: {
    dashboardHash: function() {
      this.dashboardKey++
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.leftInfo = this.menus.dashboards
      if (!this.$route.hash) {
        const dashboard = this.menus.defaultMenus.find(x => {
          return x.code == 'dashboard'
        })
        // const hash = dashboard.href ? dashboard.href.split('#/')[1] : ''
        const dashboardID = this.$route.query.dashboardID || (dashboard.href ? dashboard.href.split('#/')[1] : '')
        this.$router.replace({ path: this.$route.path + '#/' + dashboardID })
        // this.$router.replace({ path: this.$route.path, query: { dashboardID } })
      }
    },
    // 批量查询 点击修改时 先循环一下书签 确定是否满足条件 然后修改查询条件 清空当前数据 请求接口赋值数据
    changeAlltime(item) {
      this.$refs['mbdashboard'].changeAlltime(item)
    },
  },
  mounted() {
    document.addEventListener('gesturestart', function(e) {
      e.preventDefault()
    })

    document.addEventListener('dblclick', function(e) {
      e.preventDefault()
    })
    document.documentElement.addEventListener(
      'touchstart',
      function(event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      },
      false
    )
    let lastTouchEnd = 0
    document.documentElement.addEventListener(
      'touchend',
      function(event) {
        let now = Date.now()
        if (now - lastTouchEnd <= 300) {
          event.preventDefault()
        }
        lastTouchEnd = now
      },
      false
    )
  },
}
</script>

<style lang="scss">
.mobile {
  padding-top: 42px;
  height: 100%;
  // overflow-y: scroll;
  .dashboardList {
    height: 100%;
    min-height: 100%;
  }
}
</style>
