<template>
  <div class="holiday">
    <el-select
      popper-class="thmemBottomMenu zIndexMax"
      v-model="holidayYear"
      size="mini"
      placeholder="请选择"
      @change="
        item => {
          checkHolidayYear(item, currenttime.mode)
        }
      "
    >
      <el-option v-for="(m, midx) in holidayYears" :key="midx" :label="m.label" :value="m.value"></el-option>
    </el-select>
    <ul class="dis_flex">
      <li v-for="(m, fidx) in festivals" :key="fidx + currenttime.mode">
        <span class="disabled" v-if="m.disabled">{{ m.name }}</span>
        <span class="canclick" v-else @click="clickfestival(m)">{{ m.name }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import datejson from './datejson'
import datejs from './datetimejs.js'
export default {
  props: ['currenttime'],
  data() {
    return {
      datejson: datejson,
      datejs: datejs,

      holidayYear: '',
      holidayYears: [],
      festivals: [],
    }
  },
  components: {
    // datetime,
  },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
    }),
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const _this = this
      this.getAllYears()
    },
    getAllYears() {
      const yearLength = Number(
        this.moment()
          .subtract(2012, 'year')
          .format('YY')
      )
      let list = {}
      for (let i = 0; i <= yearLength; i++) {
        list = {
          value: 2012 + i,
          label: 2012 + i,
        }
        this.holidayYears.push(list)
      }
      this.holidayYear = this.holidayYears[yearLength].label
      if (this.basicInfo) {
        this.checkHolidayYear(this.holidayYear, this.currenttime.mode)
      }
    },
    checkHolidayYear(item, type) {
      let currentType = type == 'CustomFestival' ? 'Custom' : 'Statutory'
      this.festivals = this.basicInfo.festivals
        .filter(x => {
          return x.year == item && x.type == currentType
        })
        .map(x => {
          x.disabled = this.moment(x.start).isAfter(this.moment())
          return x
        })
    },
    clickfestival(item) {
      let date = []
      date[0] = item.start
      date[1] = item.end
      this.currenttime.start = item.start
      this.currenttime.end = item.end
      datejs.formattime(this, this.currenttime)
    },
    changekList(item) {
      datejs.checkList(this, item)
    },
    changeitem(i, item) {
      this.$emit('changeitem', i, item)
    },
  },
}
</script>

<style lang="scss">
.zIndexMax {
  z-index: 99999999 !important;
}
</style>
