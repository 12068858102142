<template>
  <div class="item">
    <div class="total dis_flex" v-if="isShowTotal != 'undefined' && isShowTotal">
      <el-select
        popper-class="thmemBottomMenu"
        v-model="aggregation"
        size="mini"
        @change="totalChange"
        :style="{ width: aggregation == 'sum' ? '45px' : '60px' }"
      >
        <el-option v-for="item in totalOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <span class="totalnum">{{ totalNum }}</span>
    </div>
    <div v-if="itemdata.reqObj.mode == 'funnel'" :style="{ width: '100%', height: isShowTotal ? 'calc(100% - 43px)' : '100%' }">
      <div v-if="funnelname != '总体'" class="checkfunnel">
        <el-popover
          :key="popoverkey"
          v-model="funnelvisible"
          popper-class="funnel-popover thmemBottomMenu"
          placement="bottom-start"
          title=""
          width="200"
          trigger="click"
          @hide="hidepopper"
        >
          <input type="text" v-model="funnelFilter" placeholder="搜索" @keyup="filterWord" />
          <el-radio-group v-model="funnelradio" @change="funnelChange">
            <el-radio v-for="(item, idx) in showtotal" :label="idx" :key="idx">{{ item.title }}</el-radio>
          </el-radio-group>
          <el-button class="checkname" type="text" slot="reference">共{{ showtotal.length }}组 <i class="el-icon-arrow-down"></i></el-button>
        </el-popover>
      </div>
      <div class="funnelTop">
        <div class="tit">
          转化率
        </div>
        <div class="funnelname" v-if="funnelname != '总体'">( {{ funnelname }}:{{ funneldimension }} )</div>
        <b v-text="funnelrate"></b>
      </div>
      <div :ref="'echartitem' + itemdata.index" :style="{ width: '100%', height: funnelname == '总体' ? 'calc(100% - 55px)' : 'calc(100% - 75px)' }"></div>
    </div>
    <div
      v-if="isShowTotal != 'undefined' && itemdata.reqObj.mode != 'funnel'"
      :ref="'echartitem' + itemdata.index"
      style="width: 100%;"
      :style="{ height: isShowTotal ? 'calc(100% - 20px)' : '100%' }"
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

let myChart = ''
export default {
  name: 'item',
  props: ['resdata', 'combinedcard'],
  data() {
    return {
      itemdata: null,
      myChart: null,
      funnelradio: 0,
      funnelFilter: '',
      funnelvisible: false,
      popoverkey: 0,

      funnelname: '',
      funneldimension: '',

      funnelrate: '',
      showtotal: [],

      isShowTotal: false,
      Totals: null,
      totalNum: '',
      totalOptions: [],

      aggregation: '',
    }
  },
  watch: {},
  computed: {},
  created() {
    this.itemdata = this.resdata
    if (!this.combinedcard) {
      this.getShowTotal()
    }
    this.init()
  },
  methods: {
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      if (!this.$echarts) return
      myChart = this.$echarts.init(this.$refs['echartitem' + this.itemdata.index], null, { renderer: 'svg' })

      // 绘制图表
      this.itemdata.option.color = this.itemdata.chartColorV2
      this.itemdata.option.animation = false
      myChart.setOption(this.itemdata.option)

      //如果是关系图
      if (this.itemdata.option.series && this.itemdata.option.series.type == 'graph') {
        myChart.on('mouseover', function(data) {
          // 取消节点连接线触发效果
          if (data.dataType == 'edge') {
            myChart.dispatchAction({
              type: 'unfocusNodeAdjacency',
              seriesIndex: 0,
            })
          }
        })
      }
      this.$refs['echartitem' + this.itemdata.index].childNodes[0].childNodes[0].childNodes[0].remove()
    },
    funnelChange(item) {
      let seriesdata = []
      let ser = {
        type: 'funnel',
        data: [],
        top: 26,
        bottom: 20,
      }
      if (this.itemdata.searchCode == 'template' || (this.itemdata.version === '2.0' && this.itemdata.cardtype === 'funnel')) {
        this.itemdata.option.columns.forEach(x => {
          if (x.usage == 'Measure') {
            let list = {
              name: x.title,
              value: this.itemdata.option.total[item][x.alias],
            }
            ser.data.push(list)
          }
        })
        this.funneldimension = this.itemdata.option.total[item].title
      } else {
        let funnel = this.itemdata.option.envData.funnelDic[this.itemdata.reqObj.ID]
        ser.data = funnel.steps.map((x, index) => {
          let idx = index + 1
          return {
            name: this.itemdata.option.envData.eventDic[x.code].text,
            value: this.itemdata.option.total[item]['conversion_count_' + idx],
          }
        })
        this.funneldimension = this.itemdata.option.total[item].dimension
      }
      seriesdata.push(ser)
      this.itemdata.option.series = seriesdata
      myChart = this.$echarts.init(this.$refs['echartitem' + this.itemdata.index])
      myChart.setOption(this.itemdata.option, true)
      myChart.resize()
      this.funnelname = this.itemdata.option?.funnelTips?.name || ''
      this.funnelrate = this.itemdata.option?.total[item]?.value || ''

      this.funnelvisible = false
    },
    filterWord() {
      let copytotal = JSON.parse(JSON.stringify(this.itemdata.option.total))
      if (this.funnelFilter) {
        this.showtotal = copytotal.filter(x => {
          return x.dimension.includes(this.funnelFilter)
        })
      } else {
        this.showtotal = copytotal
      }
    },
    totalChange(item) {
      if (item == 'sum') {
        this.totalNum = this.$commonJs2.thousandBitNum(String(this.Totals.num).includes('.') ? this.Totals.num.toFixed(2) : this.Totals.num)
      } else if (item == 'average') {
        this.totalNum = this.$commonJs2.thousandBitNum((this.Totals.num / this.Totals.allLen).toFixed(2))
      } else {
        this.totalNum = this.$commonJs2.thousandBitNum(this.Totals.median.toFixed(2))
      }
    },
    getShowTotal() {
      const version = this.itemdata.version
      let reqObj = this.itemdata.reqObj
      this.aggregation =
        version == '2.0'
          ? reqObj.settings.styleSet.aggregation
          : reqObj.displaySettings
          ? reqObj.displaySettings.aggregation
          : reqObj.setting
          ? reqObj.setting.aggregation
          : reqObj[0]
          ? reqObj[0].displaySettings.aggregation
          : false
      let inittableObj = this.itemdata.inittableObj
      if (inittableObj && inittableObj.Total) {
        let totals = Object.keys(inittableObj.Total)
        let noContrastHead = inittableObj.thead.filter(x => {
          return !(x['prop'].includes('_last') || x['prop'].includes('_lastRate') || x['prop'].includes('_circle') || x['prop'].includes('_circleRate'))
        })
        let totalLen = totals.filter(x => {
          return !(x.includes('_last') || x.includes('_lastRate') || x.includes('_circle') || x.includes('_circleRate'))
        })
        if (
          noContrastHead.length == 2 &&
          totalLen.length == 1 &&
          this.aggregation &&
          this.aggregation != 'none' &&
          !['table', 'table_hotmap', 'table_more', 'table_path', 'sankey'].includes(reqObj.mode)
        ) {
          this.isShowTotal = true
        } else {
          this.isShowTotal = false
        }
        if (this.isShowTotal) {
          this.Totals = inittableObj.Total[totalLen[0]]
          this.totalOptions = [
            {
              value: 'sum',
              label: '总计',
            },
            {
              value: 'average',
              label: '平均值',
            },
            {
              value: 'median',
              label: '中位数',
            },
          ]
          this.totalChange(this.aggregation)
        }
      }
    },
    hidepopper() {
      this.popoverkey++
    },
    init() {
      if (this.itemdata.reqObj.mode == 'funnel') {
        this.funnelname = this.itemdata.option?.funnelTips?.name || ''
        this.funnelrate = this.itemdata.option?.total[0]?.value || ''
        this.showtotal = JSON.parse(JSON.stringify(this.itemdata.option.total))

        if (this.itemdata.searchCode == 'template') {
          this.funneldimension = this.itemdata.option.total[0].title
        } else {
          this.funneldimension = this.itemdata.option.total[0].dimension
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.drawChart()
    })
  },
}
</script>
<style lang="scss">
.item {
  .total {
    .el-select {
      margin: 0;
      .el-input {
        input {
          border: none !important;
          height: 20px;
          line-height: 20px;
        }
        .el-input__inner {
          color: #adb5bd !important;
          padding-right: 20px;
          padding-left: 1px;
          background: none;
        }
        .el-input__suffix {
          top: 4px;
          .el-input__icon {
            width: 15px;
          }
        }
        &.is-focus {
          .el-input__suffix {
            top: -4px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.isWeb {
  // .checkname {
  //   position: absolute;
  //   top: -40px !important;
  //   left: 190px;
  // }
}
.item {
  width: 100%;
  height: 100%;
  .total {
    .totalnum {
      margin-left: 5px;
      font-size: 16px;
      @include high_color1($highcolor-cheng);
    }
  }
  .checkfunnel {
    position: relative;
    .checkname {
      position: absolute;
      top: -10px;
    }
    .el-button--text {
      color: #adb5bd;
    }
  }
  .funnelTop {
    text-align: center;
    .tit {
      display: inline-block;
      position: relative;
      font-size: 14px;
      @include theme_color2($theme-light);
    }
    .funnelname {
      display: block;
      white-space: nowrap;
      font-size: 14px;
      color: #adb5bd;
      transform: scale(0.9);
    }
    b {
      display: block;
      font-size: 32px;
      font-family: newfont1;
      @include high_color1($theme-light);
    }
  }
}

.funnel-popover {
  > input {
    line-height: 24px;
    margin-bottom: 10px;
    padding-left: 10px;
    outline: none;
    @include theme_color2($theme-dark);
    @include theme_border_color($theme-border-light);
    @include theme_bg1($theme-light);
  }
  .el-radio-group {
    width: 100%;
    max-height: 250px;
    overflow: auto;
    .el-radio {
      display: block;
      margin: 5px 0;
      @include theme_color2($theme-dark);
    }
  }
}

.full {
  .item {
    .total {
      // display: none;
      // padding-top: 20px;
    }
  }
}
</style>
