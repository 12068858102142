<template>
  <div class="mobilehead">
    <div class="mb-head-top">
      <div class="mb-head-left">
        <div class="name">
          <el-button type="text" size="small" @click="drawer = true">{{ currentInfo.name }}<i class="el-icon-caret-bottom"></i></el-button>
        </div>
        <!-- <div class="mb-head-filter" @click="filtervisible = true"><i class="iconfont iconfunnel"></i> 筛选</div> -->
        <datatime2 :proptimeidx="-1" :isMobile="true" @timechange="gettimeRange"></datatime2>
      </div>
      <div class="mb-head-right">
        <a @click="linklist"><i class="iconfont iconcaidan"></i></a>
      </div>
    </div>
    <el-drawer title="标题" custom-class="drawerBox" :with-header="false" :visible.sync="drawer" :direction="direction">
      <el-tree
        ref="treeList"
        v-if="leftInfo"
        :data="leftInfo"
        node-key="id"
        icon-class=""
        :default-expanded-keys="expandedid"
        :current-node-key="currentid"
        :allow-drop="allowDrop"
        :props="defaultProps"
      >
        <span
          class="slottree"
          slot-scope="{ node, data }"
          :class="{ notfolder: data.nodeType !== 'Folder', folder: data.nodeType === 'Folder', currentid: data.id == currentid && data.nodeType === 'Page' }"
        >
          <span @click="clicknode(node)">{{ node.label }}</span>
        </span>
      </el-tree>
    </el-drawer>
  </div>
</template>

<script>
import datatime2 from './datatimeV2/index2.vue'
import BI_config from '@/utils/config'
import { mapState } from 'vuex'
export default {
  props: ['propleftinfo'],
  components: {
    datatime2,
  },
  data() {
    return {
      leftInfo: '',
      currentInfo: '', //当前选中菜单
      drawer: false,
      direction: 'ltr',
      expandedid: [],
      currentid: '',
      defaultProps: {
        children: 'children',
        label: 'name',
      },

      filtervisible: false,
    }
  },
  computed: {
    ...mapState({
      projectID: state => state.projectID,
    }),
  },
  created() {
    this.leftInfo = this.propleftinfo
    this.init()
  },
  methods: {
    init() {
      this.currentid = this.$route.hash.replace('#/', '')
      this.leftInfo.forEach(x => {
        if (x.nodeType === 'Folder') {
          const childs = x.children.find(c => {
            return c.id == this.currentid
          })
          if (childs) {
            this.currentInfo = childs
          }
        } else {
          if (x.id == this.currentid) {
            this.currentInfo = x
          }
        }
      })
      this.expandedid.push(Number(this.currentid))
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.level == draggingNode.data.level) {
        if (dropNode.data.nodeType !== 'Folder' || draggingNode.data.nodeType === 'Folder') {
          return type === 'prev' || type === 'next'
        } else {
          return true
        }
      }
    },
    clicknode(node) {
      const cnode = node.data
      if (cnode.nodeType === 'Folder') {
      } else if (cnode.isOutsideUrl) {
        const url = this.$router.resolve({ name: cnode.url })
        window.open(url.location.name, '_blank')
      } else {
        this.currentInfo = cnode
        this.$router.push({ path: BI_config.dashboardProxy + '/Mobile/' + this.projectID + '#/' + cnode.id })
        this.$store.commit('setdashboardHash', cnode.id)
        this.currentid = cnode.id
        this.drawer = false
      }
    },
    gettimeRange(item) {
      this.$emit('changealltime', item)
    },
    linklist() {
      window.location.href = `${BI_config.SiteHost}${BI_config.dashboardProxy}/MobileList`
    },
  },
}
</script>

<style lang="scss" scoped>
.mobilehead {
  width: 100%;
  .mb-head-top {
    width: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
    display: flex;
    line-height: 40px;
    padding: 0 10px;
    @include theme_bg1($theme-light);
  }
  .mb-head-left {
    display: flex;
    flex: 1;
    margin-right: 20px;
    .name {
      flex: 1;
      display: flex;
      align-items: center;
      .el-button {
        @include theme_color2($theme-light);
        font-size: 16px;
        i {
          @include theme_color2($theme-light);
        }
      }
    }
  }
  .mb-head-right {
    font-size: 14px;
    display: flex;
    align-items: center;
    a {
      @include theme_color2($theme-light);
      i {
        font-size: 18px;
      }
    }
  }
}
</style>
<style lang="scss">
.filter-dialog {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

.drawerBox {
  width: 50% !important;
  height: 100%;
  padding: 15px 0;
  overflow-y: auto !important;
  .el-drawer__header {
    display: none;
  }
  .el-tree {
    .el-tree-node {
      .el-tree-node__content {
        .slottree {
          span {
            color: #333;
          }
          &.notfolder {
            span {
              color: #909399;
            }
          }
          &.currentid {
            span {
              color: #3aa0ff;
            }
          }
        }
      }
    }
  }
}
</style>
