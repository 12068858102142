<template>
  <div v-if="customSecond.length > 0" class="pasttopast">
    <span>从过去第</span>
    <el-input v-model="customSecond[0].passVal" size="mini" @input="customSecondChange"></el-input>
    <el-select popper-class="thmemBottomMenu zIndexMax" v-model="dateType" size="mini" placeholder="请选择" @change="customSecondChange">
      <el-option v-for="m in customSecond[0].passcustomopts" :key="m.value" :label="m.label" :value="m.value"></el-option>
    </el-select>
    <span>至 过去第</span>
    <el-input v-model="customSecond[1].passVal" size="mini" @input="customSecondChange"></el-input>
    <span v-text="datejson.dateDictionary[dateType]"></span>
  </div>
</template>

<script>
import datejson from '../datejson'
export default {
  props: ['currenttime'],
  data() {
    return {
      datejson: datejson,
      dateType: 'Day', // 时间粒度
      customSecond: [], //
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const relative = this.currenttime.relative
      // 初始化 是否回显
      if (!relative || (relative.mode && relative.mode !== 'FromPastToPast') || relative.defines.length == 1) {
        this.customSecond = [
          {
            passVal: '7',
            passcustomopts: datejson.timeunits,
          },
          {
            passVal: '1',
          },
        ]
      } else {
        // 若是回显
        this.dateType = relative.unit
        if (relative.defines.length == 2) {
          this.customSecond = [
            {
              passVal: -relative.defines[0].unitValue,
              passcustomopts: datejson.timeunits,
            },
            {
              passVal: -relative.defines[1].unitValue,
            },
          ]
          this.getCustomTime()
        }
      }
    },
    customtimechange() {},
    customSecondChange(item) {
      this.exportcurrenttime()
    },
    getCustomTime() {
      let relative = {
        mode: 'FromPastToPast',
        unit: this.dateType,
        defines: [
          {
            unitValue: '-' + this.customSecond[0].passVal,
          },
          {
            unitValue: '-' + this.customSecond[1].passVal,
          },
        ],
      }
      this.currenttime.relative = relative
      this.$emit('timechange')
    },

    exportcurrenttime() {
      this.getCustomTime()
    },
  },
}
</script>

<style lang="scss" scoped>
.pasttopast {
  > .el-input {
    width: 50px !important;
  }
  > .el-select {
    width: 70px !important;
  }
}
</style>
<style lang="scss">
  .zIndexMax{
    z-index: 99999999 !important;
  }
</style>
