<template>
  <div v-if="customThird.length > 0">
    <div class="passcustom-item" v-for="(pass, passidx) in customThird" :key="passidx">
      <el-select popper-class="thmemBottomMenu zIndexMax" v-model="passCustom" size="mini" placeholder="请选择" @change="passCustomChange">
        <el-option v-for="m in pass.passcustomopts" :key="m.value" :label="m.label" :value="m.value"></el-option>
      </el-select>
      <el-select
        popper-class="thmemBottomMenu zIndexMax"
        v-model="pass.monthVal"
        v-if="passCustom == 'Year'"
        size="mini"
        placeholder="请选择"
        @change="passMonthChange(passidx)"
      >
        <el-option v-for="m in pass.months" :key="m.value" :label="m.label" :value="m.value"></el-option>
      </el-select>
      <el-select
        popper-class="thmemBottomMenu zIndexMax"
        v-model="pass.dayVal"
        size="mini"
        placeholder="请选择"
        @change="passDayChange"
        :class="{ daoshu: pass.dayVal < 0 }"
      >
        <el-option v-for="m in pass.days" :key="m.value" :label="m.label" :value="m.value"></el-option>
      </el-select>
      <span v-if="passidx == 0">至</span>
    </div>
  </div>
</template>

<script>
import datejson from '../datejson'
import datejs from '../datetimejs.js'
export default {
  props: ['currenttime'],
  data() {
    return {
      datejson: datejson,
      passCustom: 'Year',
      customThird: [], //
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const relative = this.currenttime.relative
      let months = Array.apply(null, Array(12)).map((item, i) => {
        return {
          value: i + 1 + '',
          label: i + 1 + '月',
        }
      })
      let lastCurMon =
        this.moment()
          .subtract(1, 'year')
          .format('YYYY') + '-01'
      let curMon = this.moment().format('YYYY') + '-01'
      let lastCurDays = datejs.getMonDays(this, lastCurMon)
      let curDays = datejs.getMonDays(this, curMon)
      // 初始化 是否回显
      if (!relative || (relative.mode && relative.mode !== 'FromLastToThis') || relative.defines.length == 1) {
        this.customThird = [
          {
            passcustomopts: datejson.passcustomopts1,
            monthVal: '1',
            months: months,
            dayVal: '1',
            days: lastCurDays,
          },
          {
            passcustomopts: datejson.passcustomopts2,
            monthVal: '1',
            months: months,
            dayVal: '1',
            days: curDays,
          },
        ]
      } else {
        // 若是回显
        this.passCustom = relative.unit
        if (relative.defines.length == 2) {
          relative.defines.forEach(x => {
            if (relative.unit == 'Year') {
              let valueSplit = x.value.split('-')
              let list = {
                monthVal: parseInt(valueSplit[0]),
                dayVal: parseInt(valueSplit[1]),
              }
              this.customThird.push(list)
            }
            if (['Month', 'Week'].includes(relative.unit)) {
              let list = {
                dayVal: x.value,
              }
              this.customThird.push(list)
            }
          })
          this.getCustomRelative()
        }
      }
    },
    passCustomChange(item) {
      this.passCustom = item
      this.getCustomRelative('change')
      this.getCustomTime()
    },
    passMonthChange(idx) {
      //切换月份 更新每月天数
      const monthval = this.customThird[idx].monthVal
      let mon = ''
      if (idx == 0) {
        mon =
          this.moment()
            .subtract(1, 'year')
            .format('YYYY') +
          '-' +
          monthval
      } else {
        mon = this.moment().format('YYYY') + '-' + monthval
      }
      const dayslist = datejs.getMonDays(this, mon)
      this.$set(this.customThird[idx], 'days', dayslist)
      this.getCustomTime()
    },
    passDayChange() {
      this.getCustomTime()
    },
    getCustomTime() {
      let lastDayVal = String(this.customThird[0].dayVal)
      let curDayVal = String(this.customThird[1].dayVal)
      let relative = {
        mode: 'FromLastToThis',
        unit: this.passCustom == 'Day' ? 'Year' : this.passCustom,
      }
      if (this.passCustom == 'Year') {
        let curMon = String(this.customThird[0].monthVal)
        let lastMon = String(this.customThird[1].monthVal)

        lastDayVal = Number(lastDayVal) < 10 ? '0' + lastDayVal : lastDayVal
        curDayVal = Number(curDayVal) < 10 ? '0' + curDayVal : curDayVal
        curMon = curMon < 10 ? '0' + curMon : curMon
        lastMon = lastMon < 10 ? '0' + lastMon : lastMon
        relative.defines = [
          {
            unitValue: -1,
            value: curMon + '-' + lastDayVal,
          },
          {
            unitValue: 0,
            value: lastMon + '-' + curDayVal,
          },
        ]
      }
      if (this.passCustom == 'Month') {
        relative.defines = [
          {
            unitValue: -1,
            value: lastDayVal,
          },
          {
            unitValue: 0,
            value: curDayVal,
          },
        ]
      }
      if (this.passCustom == 'Week') {
        relative.defines = [
          {
            unitValue: -1,
            value: lastDayVal,
          },
          {
            unitValue: 0,
            value: curDayVal,
          },
        ]
      }
      if (this.passCustom == 'Day') {
        this.passCustom = 'Year'
        this.$set(this.currenttime, 'unit', 'Year')
        relative.defines = [
          {
            unitValue: -1,
            value: '01-01',
          },
          {
            unitValue: 0,
            value: '01-01',
          },
        ]
      }
      this.currenttime.relative = relative
      this.$emit('timechange')
    },
    getCustomRelative(ischange) {
      this.currenttime.unit = this.passCustom
      this.currenttime.value = 0
      let time = datejs.getshowdata(this, this.currenttime, 'nofrom').split('~')
      if (this.passCustom == 'Year') {
        let months = Array.apply(null, Array(12)).map(function(item, i) {
          return {
            value: i + 1 + '',
            label: i + 1 + '月',
          }
        })
        let lastCurMon =
          this.moment()
            .subtract(1, 'year')
            .format('YYYY') + '-01'
        let curMon = this.moment().format('YYYY') + '-01'
        let lastCurDays = datejs.getMonDays(this, lastCurMon)
        let curDays = datejs.getMonDays(this, curMon)
        if (this.customThird.length == 2) {
          this.customThird = [
            {
              passcustomopts: this.datejson.passcustomopts1,
              monthVal: ischange ? '1' : String(this.customThird[0].monthVal),
              months: months,
              dayVal: ischange ? '1' : String(this.customThird[0].dayVal),
              days: lastCurDays,
            },
            {
              passcustomopts: this.datejson.passcustomopts2,
              monthVal: ischange ? '1' : String(this.customThird[1].monthVal),
              months: months,
              dayVal: ischange ? '1' : String(this.customThird[1].dayVal),
              days: curDays,
            },
          ]
        } else {
          this.customThird = [
            {
              passcustomopts: this.datejson.passcustomopts1,
              monthVal: '1',
              months: months,
              dayVal: '1',
              days: lastCurDays,
            },
            {
              passcustomopts: this.datejson.passcustomopts2,
              monthVal: '1',
              months: months,
              dayVal: '1',
              days: curDays,
            },
          ]
        }
        this.currenttime.start = ischange ? lastCurMon + '-01' : time[0]
        this.currenttime.end = ischange ? curMon + '-01' : time[1]
      } else if (this.passCustom == 'Month') {
        let curMon = this.moment().format('YYYY-MM')
        let lastCurMon = this.moment()
          .subtract(1, 'months')
          .format('YYYY-MM')
        let curDays = datejs.getMonDays(this, curMon)
        let lastCurDays = datejs.getMonDays(this, lastCurMon)
        if (this.customThird.length == 2) {
          this.customThird = [
            {
              passcustomopts: this.datejson.passcustomopts1,
              dayVal: ischange ? '1' : String(this.customThird[0].dayVal),
              days: lastCurDays,
            },
            {
              passcustomopts: this.datejson.passcustomopts2,
              dayVal: ischange ? '1' : String(this.customThird[1].dayVal),
              days: curDays,
            },
          ]
        }
        this.currenttime.start = lastCurMon + '-01'
        this.currenttime.end = curMon + '-01'
      } else if (this.passCustom == 'Week') {
        let nubs = ['一', '二', '三', '四', '五', '六']
        let weeks = Array.apply(null, Array(6)).map(function(item, i) {
          return {
            value: i + 1 + '',
            label: '周' + nubs[i],
          }
        })
        const sunday = { value: 0, label: '周日' }
        weeks.push(sunday)
        if (this.customThird.length == 2) {
          this.customThird = [
            {
              passcustomopts: this.datejson.passcustomopts1,
              dayVal: ischange ? '1' : String(this.customThird[0].dayVal),
              days: weeks,
            },
            {
              passcustomopts: this.datejson.passcustomopts2,
              dayVal: ischange ? '1' : String(this.customThird[1].dayVal),
              days: weeks,
            },
          ]
        }
        let lastCurDay = this.moment()
          .weekday(-6)
          .format('YYYY-MM-DD')
        let curDay = this.moment()
          .weekday(1)
          .format('YYYY-MM-DD')
        this.currenttime.start = ischange ? lastCurDay : time[0]
        this.currenttime.end = ischange ? curDay : time[1]
      }
    },

    exportcurrenttime() {
      this.getCustomRelative()
      this.getCustomTime()
    },
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
  .zIndexMax{
    z-index: 99999999 !important;
  }
</style>